@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.banner {
    background-repeat: no-repeat;
    background-size: min(22vmax, 22em);
    background-position: 85% bottom;
    min-height: min(60vmax, 37.5em);

    display: flex;
    // align-items: center;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        @include box(min(47vmax, 47em));

        position: absolute;
        z-index: -1;
        bottom: -30%;
        right: -5%;
        border-radius: 50%;
        background-color: #eef2f6;
    }

    &::after {
        content: "";
        display: block;
        @include box(min(30vmax, 30em));

        position: absolute;
        z-index: -1;
        bottom: 57%;
        left: -15%;
        border-radius: 50%;
        background-color: #eef2f6;

        @include media(md) {
            bottom: -27%;
        }
    }

    @include media(md) {
        align-items: center;
    }
}
.topTitle {
    font-size: min(5vmax, 5em);
}
.blueSection {
    background-color: rgba(155, 178, 207, 0.15);
}
.title span {
    font-size: 33.3333333%;
    display: inline-block;
    max-width: 15em;
    text-transform: none;
    color: var(--dark);
    font-weight: normal;
    font-family: var(--ff-public);
}
.description {
    font-size: min(2.25vmax, 1.5em);
    line-height: 1.3;
    max-width: 35em;
}

.cardDeck {
    @extend %reset-list;
    display: grid;
    gap: min(2vmax, 1.875em);

    @include media(sm) {
        grid-template-columns: repeat(auto-fit, minmax(25em, 1fr));
    }
}
.card {
    border-radius: 15px;
    background-color: var(--white);
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    &_br_pr {
        @extend .card;
        border: 3px solid var(--public-primary);
    }
    &_br_ac {
        @extend .card;
        border: 3px solid var(--public-accent);
    }
    &_with_icons {
        @extend .card_br_pr;
        padding: min(2vmax, 1.5em);
        gap: min(1.5vmax, 1em);
        justify-content: center;
        align-items: center;

        @include media(sm) {
            flex-direction: row;
        }
    }
    &_with_fest_icons {
        @extend .card_with_icons;
        @extend .card_br_ac;
    }
}
.cardTitle {
    margin: 0;
    text-align: center;
    font-size: min(2.5vmax, 1.875em);
    text-transform: uppercase;
    box-sizing: border-box;

    &_bg_pr {
        display: flex;
        @extend .cardTitle;
        align-items: center;
        justify-content: center;
        padding: 1em;

        color: var(--white);
        border-radius: 13px;
        min-height: min(15vmax, 8.5rem);
        outline: 3px solid var(--public-accent);
        background-color: rgba(182, 14, 63, 0.4);
    }
    &_bg_ac {
        @extend .cardTitle_bg_pr;
        background-color: #9bb2cf;
    }
    &_pr {
        @extend .cardTitle;
        font-weight: 600;
        color: var(--public-primary);
        font-size: min(2vmax, 1.5em);

        @include media(sm) {
            text-align: left;
        }
    }
    &_ws {
        @extend .cardTitle;
        span {
            display: block;
            // margin-top: 1em;

            font-weight: 600;
            font-size: 66.6666667%;
            text-transform: lowercase;
        }
    }
}
.cardIcon {
    flex: none;
    display: block;
    object-fit: contain;
    width: min(8vmax, 8em);

    &_fest {
        width: min(10vmax, 10em);
    }
}
.cardText {
    margin: auto;
    padding: 1em;
    box-sizing: border-box;

    text-align: center;
    font-size: min(2vmax, 1.25em);
    font-weight: normal;
    line-height: 1.3;

    span {
        font-weight: 600;
        display: block;
        margin: 1em 0;
    }
}
.bigText {
    text-align: center;
    font-size: min(2.5vmax, 1.875em);
    font-weight: bold;
    margin: auto;
    padding: 1em;
}
.cardList {
    @extend %reset-list;
    display: flex;
    gap: 1em;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: auto;
    padding: 1em;
    box-sizing: border-box;

    font-size: min(2vmax, 1.25em);

    li span {
        display: block;
        font-weight: bold;
    }
}

.feedback {
    font-weight: 600;
    font-size: min(2.75vmax, 2.1875em);
    line-height: 1.3;
    max-width: 26em;

    a {
        text-decoration: none;
        color: var(--public-primary);

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        color: var(--public-primary);
    }
}
