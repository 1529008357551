@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.tabs {
    &_theme_public {
        .list {
            border-bottom: unset;
        }
        .item {
            text-transform: uppercase;
            font-size: 1.25em;
            color: var(--darkLow);
            border-radius: 5px;

            &_actived {
                font-weight: 600;
                color: var(--public-primary);
            }
        }
    }
}

.list {
    @extend %reset-list;
    display: flex;
    flex-wrap: wrap;
    gap: 0.875em 0.5em;
    box-sizing: border-box;
    // overflow-x: auto;
    // @include scroll-bar(8px);

    margin-bottom: min(2.5vmax, 1.25em);
    border-bottom: 2px solid rgba(map-get($colors, "primary"), 0.54);
    padding: 0.5em;
}

.item {
    padding: 0.5em 0.875em;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    transition-property: background-color, color;

    flex: none;
    // @extend %cutText;
    // min-width: 4em;

    border-radius: 25px;

    &:not(&_actived):hover {
        color: var(--primary);
    }

    &_actived {
        // font-weight: 600;
        color: var(--primary);
        background-color: rgba(map-get($colors, "primary"), 0.1);
    }
}

.eventIcon {
    position: relative;
    width: 1.25em;
    margin-left: 0.375em;
    display: inline-block;
    svg {
        position: absolute;
        right: 0;
        bottom: -0.25em;

        width: 1.25em;
        fill: var(--primary);
    }
}

.iconAlert {
    color: var(--error);
}
