@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.container {
    max-width: 35em;
    display: flex;
    gap: 0.785em;
    flex-direction: column;
}
.title {
    text-align: center;
    margin: 0;
}
.span_accent {
    font-weight: 600;
}
.bar {
    position: relative;
    height: 1em;
    background-color: var(--g200);
    box-shadow: inset 0 0 5px rgba(black, 0.15);
    border-radius: 10px;
    overflow: hidden;
}
.persent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: 0.35s ease-in-out;
    transition-property: width, background-color;
}
.count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75em;
    font-weight: 500;
    mix-blend-mode: multiply;
}
