.form {
    display: flex;
    gap: 0.75em;
    flex-direction: column;

    // Для разметки колонок в формах
    &__container {
        &.--view-one-column {
            max-width: 45em;
        }

        &.--view-two-columns {
            display: grid;
            gap: 0 1.25em;

            .form__section {
                max-width: unset;
            }

            @include media(lg) {
                grid-template-columns: 0.55fr 0.45fr;
            }
        }
    }

    &__group-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5em;
    }

    &__range-block {
        display: flex;
        align-items: center;
        gap: 0 1.25em;

        .form__field {
            display: grid;
            grid-template-columns: auto 1fr;
        }
    }

    &__editor-block {
        min-width: 100%;
    }

    .form__field {
        flex: auto;
    }

    &__multy-block {
        @include media(sm) {
            display: grid;
            gap: 0 1.25em;
            align-items: start;
            grid-template-columns: min(18vmax, 15em) 1fr;

            .form__label {
                padding-top: var(--field-v-paddings);
            }

            .info {
                position: relative;
                top: unset;
                grid-column: 2/3;
            }
        }
    }

    &__label {
        margin: 0;
        display: inline-block;
        box-sizing: border-box;
        line-height: 150%;
    }

    &__title {
        margin: 0 0 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 1em;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 2px solid rgba(map-get($colors, "primary"), 0.54);
    }

    &__section {
        max-width: 45em;
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0;
        margin: 0 0 min(3.5vmax, 1.875em);
        border: none;
    }

    &__controls {
        display: flex;
        gap: 0.25em;
        flex-wrap: wrap;
    }

    &__shows {
        @extend %reset-list;
        counter-reset: number;
    }

    &__show {
        &::before {
            font-weight: 600;
            color: var(--primary);
            padding: 0.5em;
            aspect-ratio: 1/1;
            width: max-content;
            text-align: center;
            background-color: rgba(map-get($colors, "primary"), 0.15);
            counter-increment: number;
            content: counter(number);
        }
    }

    &__tab {
        margin-top: min(3vmax, 1.875em);
    }

    &__icon-btn {
        align-self: center;
        margin-left: auto;
        margin-right: auto;
    }

    &__add-btn {
        margin-top: min(3vmax, 1.875em);
        align-self: start;
    }

    &__social-icon {
        flex: none;
        @include box(1.785em);

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &__social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        @include box(2em);
        border-radius: 50%;
        box-sizing: border-box;
        align-self: center;
        text-decoration: unset;
        color: inherit;

        &:hover {
            background-color: var(--g200);
        }

        .mdi {
            line-height: 1;
            font-size: 1.25em;
        }
    }

    &__profile-img-block {
        width: min-content;
    }

    &__profile-img {
        @extend %alt-text-on-after;
        @include box(8em);
        min-width: 8em;
        max-width: 8em;
        object-fit: cover;
        border-radius: 15px;
    }

    &__profile-img-panel {
        text-align: center;
    }
}
