@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.popup_login {
    background-color: var(--white);
    background-image: linear-gradient(
            to bottom,
            rgba(black, 0.26) 0%,
            rgba(black, 0.26) 100%
        ),
        url("../../../public/images/login-bg-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    button[class^="popup_close"] {
        display: none;
    }

    @include media(sm) {
        background-image: linear-gradient(
                to bottom,
                rgba(black, 0.26) 0%,
                rgba(black, 0.26) 100%
            ),
            url("../../../public/images/login-bg.png");
        background-size: contain;
        background-position: center bottom;
    }
}

.form {
    width: 20em;
    max-width: 100%;
}

.info {
    font-size: 0.875em;
    font-style: italic;
    text-align: center;
    margin: 0;
    color: var(--error);
    opacity: 0;
    height: 0;
    pointer-events: none;
    user-select: none;

    &_actived {
        opacity: 1;
        height: auto;
    }
}

.navigation {
    display: flex;
    justify-content: space-between;
}

.link {
    font-size: 0.875em;
    color: inherit;
    text-decoration: unset;

    &:hover {
        text-decoration: underline;
    }
}
