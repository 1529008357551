@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.cardDeck {
    @extend %reset-list;
    display: flex;
    gap: min(2.5vmax, 1.25em);
    flex-direction: column;
}

.card {
    @include media(sm) {
        background-color: var(--white);
        box-shadow: 0 0 15px rgba(black, 0.15);
        transition: box-shadow 0.25s ease-in-out;

        &:hover {
            box-shadow: 0 0 15px rgba(black, 0.26);
        }
        padding: min(3vmax, 2em);
        box-sizing: border-box;
    }

    &Columns {
        @extend .card;

        display: grid;
        gap: min(3vmax, 2em);

        @include media(lg) {
            grid-template-columns: 1fr 0.5fr;
        }

        @include media(xl) {
            grid-template-columns: 1fr auto;
        }
    }
}

.cardTitle {
    max-width: 13em;

    &::after {
        content: "";
        display: block;
        margin-top: 10px;
        max-width: 5.5em;
        border-bottom: 2px solid var(--public-accent);
    }
}

.cardFigure {
    position: relative;
    // width: 15em;
    margin: auto;

    @include media(lg) {
        grid-row: 1/3;
        grid-column: 2/3;
    }

    @include media(xxl) {
        display: flex;
    }
}

.cardFigcaption {
    position: relative;
    margin: 0;
    padding: min(1.25vmax, 1.5em);
    box-sizing: border-box;
    border-radius: 15px;

    font-size: min(1.75vmax, 1.125em);

    background-color: var(--public-accent);
    color: var(--light);
    max-width: min(40vmax, 20em);
    width: 100%;
    text-align: center;

    &::after {
        content: "";
        position: absolute;
        top: 99%;
        left: 20%;
        display: block;
        background-color: var(--public-accent);
        width: min(4vmax, 4em);
        height: min(1.5vmax, 1.5em);
        clip-path: polygon(60% 0, 0 0, 100% 100%);
    }

    @include media(xxl) {
        position: absolute;
        top: -1em;
        right: 85%;

        &::after {
            left: 60%;
        }
    }
}

.cardImg {
    width: 100%;
    max-width: min(30vmax, 22em);
    object-fit: contain;
    margin-top: 1em;

    @include media(xxl) {
        margin-top: auto;
    }
}

.list {
    padding-left: min(2vmax, 1.25em);
    box-sizing: border-box;
    font-size: min(2.25vmax, 1.25em);
    list-style-type: disc;

    &Numbers {
        font-size: 1.25em;
        padding-left: min(2vmax, 1.25em);
    }

    &BigNumbers {
        @extend %reset-list;
        display: flex;
        flex-wrap: wrap;
        gap: min(2vmax, 2em);
        font-size: min(2vmax, 1.5em);
        font-weight: 600;

        li {
            flex: 1 1 30%;
            counter-increment: name;
            &::before {
                content: counter(name);
                display: block;
                color: var(--public-primary);
                font-size: 2em;
            }
        }
    }
    &Disc {
        @extend .list;
        @extend %reset-list;

        @include media(sm, max) {
            padding-left: 0;
        }

        li {
            display: flex;
            margin: 1em 0;
            gap: 1em;
            align-items: baseline;
            font-weight: 600;
            &::before {
                content: "";
                flex: none;
                display: block;
                @include box(0.875rem);
                border-radius: 50%;
                background-color: var(--public-accent);
            }
        }
    }
}

.termins {
    display: grid;
    gap: 0.5rem 2.5rem;
    font-size: 0.9em;
    text-align: justify;

    @include media(md) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto 1fr;
    }
}

.cardNotif {
    margin: 0 0 0.5rem;
    font-size: min(2.5vmax, 1.5rem);
    text-align: center;

    border-radius: 25px;
    background-color: rgba(map-get($colors, "public-primary"), 0.5);
    color: var(--light);
    padding: min(2vmax, 1.25em);
    box-sizing: border-box;

    grid-column: 1/2;
    grid-row: 1/2;

    @include media(md) {
        grid-row: 1/3;
        grid-column: 2/3;
    }
}

.cardNotifSpan {
    font-weight: bold;
    text-transform: uppercase;
    display: block;
}

.link {
    font-weight: bold;
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: underline;
    }
}

.btn {
    margin: 1em auto;
    padding: 0.5em 1.5em;
    font-size: 1.25em;
    text-align: center;
    display: block;
    max-width: 10em;
    text-decoration: none;
    border-radius: 15px;
    background-color: var(--public-primary);
    color: var(--light);
    transition: background-color 0.25s ease;

    &:hover {
        background-color: lighten(map-get($colors, "public-primary"), 5%);
    }
}

.pharagraph {
    margin: 0;
    font-size: min(2.25vmax, 1.25em);

    &Justify {
        @extend .pharagraph;
        text-align: justify;
    }
    &Border {
        @extend .pharagraph;
        font-size: min(2.5vmax, 1.5em);

        align-self: center;
        justify-content: center;
        text-align: justify;

        border: 1px solid var(--g200);
        border-radius: 15px;
        padding: min(2.5vmax, 1.25em);
        box-sizing: border-box;
    }
    &Termins {
        @extend .pharagraph;
        box-sizing: border-box;
        grid-row: 2/4;

        @include media(sm) {
            padding-left: 1.25em;
        }
    }
}

.spanAccent {
    font-weight: bold;

    &TT {
        display: block;
        margin-bottom: 2em;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.twoColumns {
    @extend %reset-list;

    gap: 1em 2.5em;
    display: grid;

    @include media(md) {
        text-align: justify;
        grid-template-columns: repeat(2, 1fr);
    }
}

.caption {
    font-size: min(2.75vmax, 1.5em);
    font-weight: bold;
    text-transform: uppercase;
}

.form {
    text-align: center;

    display: flex;
    gap: 1em;
    flex-direction: column;

    @include media(md) {
        width: min(40vmax, 35em);
        grid-column: 2/3;
        grid-row: 1/3;
    }
}

.formTitle {
    margin: auto 0 0;
    font-size: 1.5em;
    font-weight: normal;
    text-transform: uppercase;
}

.fieldset {
    border: none;
    margin: 0;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, 1fr);
}

.textarea {
    grid-column: 1/-1;
}
.submitBtn {
    grid-column: 1/-1;
}
