.teacher-list {
    @extend %reset-list;
    display: flex;
    gap: 0.675em;
    flex-wrap: wrap;

    &__item {
        text-decoration: none;
        color: inherit;

        flex-basis: min(40vmax, 25em);
        padding: 0.5em;
        box-sizing: border-box;
        border-radius: 15px;
        box-shadow: 0 0 6px rgba(black, 0.15);
        display: flex;
        gap: 0 0.875em;
        // align-items: center;
        transition: box-shadow 0.15s ease-in;

        &:hover {
            box-shadow: 0 0 15px rgba(black, 0.15);
        }
    }

    &__img {
        @include box(min(10vmax, 6.75em));
        object-fit: cover;
        border-radius: 13px;
        grid-row: span 2;
    }

    &__info {
    }

    &__title {
        margin: 0;
        font-size: 1em;
        font-weight: 500;
        margin-bottom: 0.675em;
    }

    &__span-accent {
        display: block;
        text-transform: uppercase;
        font-size: min(2vmax, 1.25em);
        font-weight: 600;
    }

    &__text {
        margin: 0;
        font-weight: 500;
    }
    &__description {
        margin: 0;
        color: var(--darkLow);
        font-size: 0.875em;
        max-width: 20em;
    }
}
