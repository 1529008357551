@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.title {
    margin: 0 0 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(map-get($colors, "primary"), 0.54);
}

.list {
    @extend %reset-list;

    display: grid;
    gap: 0.675em;
    grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));

    &_content_video {
        grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
    }

    &_portrait {
        grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
        .item {
            aspect-ratio: 210/297;
        }
    }
}
.item {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    aspect-ratio: 1/0.6666667;
    border: 1px solid var(--g300);

    &:hover .panel,
    &:hover .thumbs {
        opacity: 1;
        pointer-events: unset;
        user-select: unset;
    }

    &_empty {
        padding: min(2.5vmax, 1.875em);
        box-sizing: border-box;
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: var(--g100);
        border-style: dashed;
        transition: background-color 0.25s ease-in-out;
        cursor: default;
        text-align: center;

        &:hover {
            background-color: var(--g200);
        }
    }
}
.itemTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: white, $alpha: 0.84);
    text-align: center;
    color: var(--gark);
    font-weight: 500;
    padding: 0.675em;
    box-sizing: border-box;
}
.image {
    display: block;
    @include box(100%);
    object-fit: cover;
}
.file {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include box(100%);
    background-color: var(--g200);

    svg {
        display: block;
        width: 25%;
    }
}
.fileName {
    margin: 0;
    max-width: 90%;
    word-break: break-all;
    @include clamp(3);
}
.text {
    margin: 0;
}
.span {
    margin-top: 0.5rem;
    display: block;
    font-size: 0.75em;
}
.panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.675em;
    display: flex;
    justify-content: flex-end;
    gap: 0.375em;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: opacity 0.25s ease-in-out;
}
.currentPosition {
    position: absolute;
    bottom: 0.675em;
    left: 0.675em;

    display: flex;
    align-items: center;
    justify-content: center;

    @include box(2em);
    font-weight: 500;
    padding: 0.25em;
    box-sizing: border-box;
    background-color: rgba($color: white, $alpha: 0.75);
    border-radius: 50%;
    color: var(--garkLow);
    cursor: default;
}
.thumbs {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0.675em;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: opacity 0.25s ease-in-out;
}

.errorCaption {
    margin: 0 0 1.5em;
    font-size: 1em;
    font-weight: 500;
    color: var(--error);
    line-height: 1.5;

    svg {
        @include box(1.5em);
        vertical-align: bottom;
        fill: currentColor;
    }
}
.errorText {
    display: flex;
    flex-wrap: wrap;
    gap: min(2.75vmax, 1.25em);
    align-items: center;
    font-weight: 500;
    line-height: 1.5;
}
.errorSpan {
    font-weight: 600;
    margin-left: auto;
}

.fieldBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5em;
}
.field {
    flex: auto;
}
.iconBtn {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}
.socialLink {
    display: flex;
    align-items: center;
    justify-content: center;
    @include box(2em);
    border-radius: 50%;
    box-sizing: border-box;
    align-self: center;
    text-decoration: unset;
    color: inherit;

    &:hover {
        background-color: var(--g200);
    }

    & > span {
        line-height: 1;
        font-size: 1.25em;
    }
}

.iconAlert {
    color: var(--error);
}