@import "../styles/_assets/variables";
@import "../styles/_assets/mixins";
@import "../styles/_assets/extends";

.title_block {
    display: flex;
    align-items: center;
    gap: 0.5em;

    margin: 0 0 min(5vmax, 3.125rem);
}

.title {
    // margin: 0 0 0.375rem;
    margin: 0;
    // font-family: var(--ff-firm);
    font-family: var(--ff-base);
    font-size: min(3.25vmax, 1.875em);
    font-weight: 600;
}

.link {
    margin: 0;
    text-decoration: none;
    color: var(--dark);
    transition: color 0.25s ease-in;

    .profile_description {
        text-indent: 2.5em;
    }

    &:hover {
        color: var(--primary);

        .svgIcon svg path {
            fill: var(--primary);
        }
    }
}

.svgIcon {
    & ~ .profile_description {
        text-indent: 2.5em;
    }
    svg {
        @include box(1.2em);
        margin-right: 0.75rem;
        vertical-align: middle;
        path {
            fill: var(--dark);
            transition: fill 0.25s ease-in;
        }
    }
}

.fontFace {
    font-size: 120%;
    vertical-align: middle;
    margin-right: 0.75rem;
}

// PROFILE
.profile {
    display: grid;
    gap: min(3vmax, 2.5em);
}

.profile_img_block {
    position: relative;
    justify-self: center;
}

.profile_img {
    @extend %alt-text-on-after;
    display: block;
    width: 100%;
    max-width: min(28vmax, 15.625em);
    border-radius: 25px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.profile_row {
    grid-column: 1/-1;
    border-top: 1px solid var(--g200);
    padding: min(2.25vmax, 1.875em) 0;
    box-sizing: border-box;
}

.profile_img_input {
    display: none;
}

.profile_img_panel {
    margin-top: 0.5em;
    text-align: center;
}

.profile_info {
    padding-top: min(3.25vmax, 1.875em);
}

.profile_title {
    margin: 0 0 1.875rem;
    font-size: min(3.5vmax, 2em);
    text-transform: uppercase;
    font-weight: 700;
}

.profile_table {
    @extend %reset-list;

    display: flex;
    flex-direction: column;
    gap: min(2.5vmax, 1.25em);
}

.profile_text {
    margin: 0;
    font-size: min(2.75vmax, 1.5em);
    font-weight: 500;
    word-break: break-word;
}

.profile_description {
    display: block;
    margin: 0;
    line-height: 150%;
    color: var(--darkLow);
    font-size: min(2vmax, 0.875rem);
    font-weight: normal;
    max-width: 45rem;
}

.profile_item {
    display: inline-block;
    font-size: min(2.5vmax, 1.25em);
    font-weight: 500;
}

.profile_edit_btn {
    margin-top: min(2.5vmax, 1.25em);
}

@include media(md) {
    .profile {
        grid-template-columns: auto 1fr;
    }

    .profile_img_block {
        margin-left: min(2.25vmax, 1.875em);
        box-sizing: border-box;
    }

    .profile_row {
        padding: min(2.25vmax, 1.875em);
        box-sizing: border-box;
    }
}

.spectacleCard {
    width: 100%;
    max-width: min(35em, 100%);
    margin-bottom: min(3.25vmax, 1.875em);
    padding: 2em 1.25em 1.25em;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(black, 0.15);
    border-radius: 5px;
    position: relative;
    @include media(xs, max) {
        padding: 1.25em;
    }
}
.spectacleCardElementsRow {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em 1em;
}
.spectacleCardElementsColumn {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.spectacleCardMainText {
    margin: 1.25em 0 2.5em;
    display: flex;
    gap: 0.5em;
    flex-direction: column;
}
.spectacleCardTitle {
    margin: 0;
    font-size: min(2.5vmax, 1.25em);
    font-weight: bold;
    text-transform: uppercase;
}
.spectacleCardSubtitle {
    margin: 0;
    font-weight: 500;
    color: var(--darkLow);
}
.spectacleCardElement {
    margin: 0;
    display: flex;
    gap: 0.5em;
    align-items: center;
    svg {
        flex: none;
        max-width: 1.25em;
        min-width: 1.25em;
        @include box(1.25em);
    }
}
.spectacleCardStatus {
    position: absolute;
    inset-inline-end: 0.375rem;
    inset-block-start: 0.375rem;
    color: var(--info);
    font-size: 0.875em;
    border: 1px solid;
    border-radius: 4px;
    padding: 2px 4px;
    @include media(xs, max) {
        position: static;
        max-inline-size: max-content;
        margin-block-end: 1em;
    }
}
.spectacleCardCaption {
    margin-block: 0;
    color: var(--darkLow);
    font-weight: 500;
}
