@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.profile {
  flex: none;
  display: flex;
  gap: .5em;
  align-items: center;
  box-sizing: border-box;

  padding: .5em 0;
  max-width: calc(100% - 14vmax);
}

.content {
  display: grid;
  gap: 0 .875em;
  grid-template-columns: auto 1fr;
  cursor: pointer;
}

.img {
  grid-row: 1/3;
  @include box(3em);
  border-radius: 50%;
  object-fit: cover;
  @extend %alt-text-on-after;
}

.title {
  align-self: end;
  margin: 0;
  font-weight: 600;
  @extend %cutText;
}

.subtitle {
  margin: 0;
  color: var(--darkLow);
  line-height: 150%;
  font-size: .75em;
  @extend %cutText;
}