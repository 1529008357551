@use "sass:math";
@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

// Главный прелоадер
.preloader {
  $count: 10;
  $time: 2; //in seconds
  --preloaderSize: 60;
  --preloaderDuration: .7;

  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  @include box(100%);
  z-index: 5000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @extend %visibility-block;

  &_hidden {
    @extend %hidden-block;
    transition: visibility 0s calc(#{var(--preloaderDuration)} * 1s),
    opacity calc(#{var(--preloaderDuration)} * 1s);
  }

  &__item {
    position: relative;
    flex: none;

    @include box(calc(#{var(--preloaderSize)} * 1px));

    &>div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      @include box(calc(#{var(--preloaderSize)} * 0.35px));
      border-radius: 50%;
      animation: spin 2s ease infinite;
      background-color: var(--primary);

      &:first-child {
        animation-delay: -1.5s;
        background-color: var(--accent);
      }

      &:nth-child(2) {
        animation-delay: -1s;
      }

      &:nth-child(3) {
        animation-delay: -0.5s;
        background-color: var(--accent);
      }
    }
  }

  &__text {
    max-width: calc(#{var(--preloaderSize)} * 3px);
    line-height: 150%;
    padding-top: 1em;
    text-align: center;
    color: var(--dark);
  }
}

@include keyframes(spin) {

  0%,
  100% {
    transform: translate3d(-150%, -150%, 0)
  }

  25% {
    transform: translate3d(50%, -150%, 0)
  }

  50% {
    transform: translate3d(50%, 50%, 0)
  }

  75% {
    transform: translate3d(-150%, 50%, 0)
  }
}