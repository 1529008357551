@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.list {
    @extend %reset-list;
    @extend %main-width;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

.item {
    display: flex;
    gap: 1em;
    align-items: center;

    &:not(:first-child)::before {
        content: "";
        display: block;
        @include box(0.375em);
        border-top: 1px solid var(--public-primary);
        border-right: 1px solid var(--public-primary);
        transform: rotate(45deg) skew(-5deg, -5deg);
    }
}
.link {
    // font-size: 1.125em;
    font-size: .875em;
    color: var(--darkLow);
    text-decoration: none;
    cursor: default;
    &[href] {
        cursor: pointer;

        &:hover {
            color: var(--public-primary);
        }
    }

    &:not([href]) {
        color: var(--public-primary);
    }
}
