@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.info {
    margin: min(3vmax, 1.5em) 0 min(5vmax, 2.5em);
    padding: 1em;
    max-width: 50em;
    box-sizing: border-box;
    background-color: rgba(#ff5722, 0.1);
    border: 1px solid rgba(#ff5722, 0.54);
    color: var(--alert);
    line-height: 1.5;
}

.caption {
    margin: min(3vmax, 1.5rem) 0;
}

.list {
    margin: min(3vmax, 1.5em) 0;
    padding-left: min(3vmax, 1.5em);
    line-height: 1.5;
}

.listSpan:not(:only-of-type :last-of-type)::after {
    content: ", ";
}

.fieldset {
    border: none;
    margin: min(3vmax, 1.5em) 0;
    border-top: 1px solid var(--g400);
    padding: min(3vmax, 1.5em) 0 0;

    display: flex;
    gap: 0.5em;
    flex-direction: column;
    align-items: flex-start;
}
