@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.section {
    margin-bottom: min(5vmax, 3.5em);
}

.title {
    font-size: min(3.25vmax, 1.875em);
    font-weight: 600;
}

.titleAccent {
    font-size: 66.6666667%;
    font-weight: normal;
    color: var(--darkLow);
    padding-left: 0.25em;
    padding-right: 2rem;
}

.caption {
    font-size: min(3vmax, 1.5em);
    color: var(--lightLow);
    padding-right: 2rem;
}

.eventIcon {
    margin-right: -2rem;
    svg {
        vertical-align: middle;
        width: 1.5rem;
        fill: var(--public-primary);
    }
}

.columns {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25em;
}

.column {
    &_description {
        flex: 1 0 50%;

        display: flex;
        flex-direction: column;
    }
    &_asside {
        flex: auto;
        @include media(lg) {
            flex: 0 1 min(30vmax, 21.875em);
        }
    }
    &Iframe {
        border-radius: 5px;
        overflow: hidden;
    }
}

.ticketList {
    @extend %reset-list;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
    gap: 1.5em;
    margin: 5% 0;
    padding: min(2vmax, 1.875em) 0;
    box-sizing: border-box;
}
.ticketItem {
    max-width: 17em;

    display: flex;
    align-items: center;
    background-color: var(--public-primary);
    box-shadow: 0px 0px 24px -2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: 0.25s ease-in;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.26);
    }

    &Disabled {
        cursor: default;
        background-color: var(--white);

        &:hover {
            box-shadow: 0px 0px 24px -2px rgba(0, 0, 0, 0.15);
        }

        .ticketDate {
            border-color: var(--g500);
            color: var(--dark);
        }
        .ticketStatus {
            color: var(--dark);
        }
    }
}
.ticketStatus {
    flex: 0 0 40%;
    min-width: 40%;
    max-width: 40%;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.675em;
    color: var(--light);
    padding: 1rem 0.5rem 1rem 1rem;
    box-sizing: border-box;
}
.ticketDate {
    flex: auto;
    margin: 0;
    font-weight: 600;
    font-size: 0.75em;
    color: var(--light);
    text-transform: uppercase;
    border-left: 1px solid var(--light);
    padding: 1rem 1rem 1rem 0.5rem;
    box-sizing: border-box;
    text-align: right;
}

.poster {
    object-fit: contain;
    width: 100%;
    border-radius: 5px;
}

.file {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: var(--g200);
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        & .fileName {
            transform: translateY(-5px);
        }
        & svg {
            transform: translateY(-5px);
        }
    }

    svg {
        max-width: 40%;
        transition: transform 0.15s ease-in;
    }
}

.fileName {
    max-width: 90%;
    font-size: 1em;
    transition: transform 0.15s ease-in;
}

.list {
    @extend %reset-list;
    line-height: 1.5;
    font-size: min(2.5vmax, 1.25em);
    color: var(--darkLow);

    &_column {
        display: grid;
        gap: 0 1em;
        grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));

        @include media(lg) {
            grid-template-columns: 0.75fr 1fr;
        }
    }

    li {
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5em;
    }
}

.listAccent {
    color: var(--dark);
}

.description {
    font-size: min(2.5vmax, 1.25em);
}