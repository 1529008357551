$breakpoints: (
    "xs": 480px,
    "sm": 576px,
    "md": 768px,
    "lg": 1024px,
    "xl": 1200px,
    "xxl": 1500px,
);

@mixin media($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
    }

    @if $type ==max {
        $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
        @content;
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin box($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin scroll-bar($width: 8px, $height: 8px, $bg: var(--g100), $color: var(--primary)) {
    &::-webkit-scrollbar-button,
    &::-webkit-resizer {
        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar {
        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar-track {
        background-color: $bg;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color;
        opacity: 0.54;

        &:hover {
            opacity: 1;
        }
    }
}

@mixin clamp($numbers: 3, $line-height: 1.5) {
    @if $numbers == unset {
        max-height: unset;
        display: block;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: unset;
    } @else {
        max-height: $numbers * $line-height * 1em;
        line-height: $line-height;
        display: -webkit-box;
        -webkit-line-clamp: $numbers;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
