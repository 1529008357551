@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.section {
    margin-bottom: min(5vmax, 3.5em);
}
.title {
    margin: min(2vmax, 0.75em) 0;
    font-size: min(2.75vmax, 1.875em);
    line-height: 1.5;
    font-weight: 600;

    span {
        display: block;
        font-size: 66.6666667%;
    }
}
.text {
    margin: min(2vmax, 1em) 0;
    font-size: min(2vmax, 1.25em);
    line-height: 1.5;
}

.cardDeck {
    @extend %reset-list;

    display: grid;
    gap: min(2.75vmax, 1.5em);

    @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
    }

    &_size_lg {
        @include media(sm) {
            grid-template-columns: repeat(auto-fill, minmax(22em, 1fr));
        }
    }
}
.card {
    padding: min(3vmax, 1.875em) min(2.75vmax, 1.5em);
    box-sizing: border-box;
    background-color: var(--white);
    box-shadow: 0px 0px 24px -2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: box-shadow 0.25s ease-in;
    cursor: default;

    display: grid;
    gap: 0.75em;
    grid-template-rows: minmax(4.5em, max-content) 1fr minmax(4em, max-content);

    &:hover {
        box-shadow: 0px 0px 24px -2px rgba(0, 0, 0, 0.26);
    }

    &_type_docs {
        grid-template-rows: 1fr auto;
        min-height: min(20vmax, 13em);

        .cardTitle {
            text-transform: unset;
        }
    }
    &_type_methodological {
        padding: 1.25em;
        grid-template-rows: auto auto 1fr;

        .cardTitle {
            text-align: left;
            text-transform: initial;
            font-size: 1.25em;
            font-weight: 600;
        }
    }
}
.cardTitle {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    align-self: center;

    &_size_sm {
        font-weight: normal;
    }
}
.cardSubtitle {
    margin: 0.5em 0;
    text-align: center;
}
.cardText {
    margin-block: 0;
    font-size: 1em;
    font-style: italic;
    line-height: 1.3;
    color: var(--darkLow);
}
.cardList {
    @extend %reset-list;

    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    justify-content: space-between;

    color: var(--darkLow);
    font-size: 0.875em;
}
.cardSpanAccent {
    color: var(--dark);
    font-weight: 500;
}
.cardLink {
    text-decoration: none;

    border-top: 1px solid var(--public-primary);
    padding-top: 1em;

    margin: 0.5em 0 0;
    text-align: center;
    color: var(--public-primary);
    font-weight: 700;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &_closed {
        pointer-events: none;
        filter: grayscale(100%);
    }
}
.cardPhoto {
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 3/4.5;
    border-radius: 5px;
    margin-bottom: min(2vmax, 1em);
}
.cardIframe {
    border: none;
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 389/220;
    border-radius: 5px;
}

// Карта пьес (на общей странице)
.cardPlay {
    @extend %card-design;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}
.cardPlayImg {
    display: block;
    @extend %alt-text-on-after;
    width: 100%;
    aspect-ratio: 4.3/2.6;
    object-fit: cover;
    transition: all 0.35s;
    cursor: pointer;
}
.cardPlayTitle {
    margin: 0.5rem 1rem;
    font-size: min(2vmax, 1.25em);
    font-weight: bold;
    text-transform: uppercase;
}
.cardPlaySubtitle {
    margin: 0.5rem 1rem;
    font-style: italic;
    color: var(--darkLow);
}
.cardPlayItems {
    @extend %reset-list;
    margin: 0.75rem 1rem;
    color: var(--darkLow);
    display: flex;
    gap: 0.375em;
    flex-direction: column;
}
.cardPlayItem {
    margin: 0;
    display: flex;
    gap: 0.5em;
}
.cardPlayAccent {
    color: var(--dark);
    font-weight: 500;
}

// Карта пьес (детально)
.columns {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25em;
}
.column {
    &_description {
        flex: 1 0 50%;

        display: flex;
        flex-direction: column;
    }
    &_asside {
        flex: auto;
        @include media(lg) {
            flex: 0 1 min(30vmax, 21.875em);
        }
    }
}
.list {
    @extend %reset-list;
    line-height: 1.5;
    font-size: min(2.5vmax, 1.25em);
    color: var(--darkLow);

    &_column {
        column-count: auto;
        column-width: min(30vmax, 20em);
        column-gap: 1em;
    }
}
.listAccent {
    color: var(--dark);
}
.description {
    font-size: min(2.5vmax, 1.25em);
}
.poster {
    object-fit: contain;
    width: 100%;
    border-radius: 5px;
}
.fileLink {
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--info);
    text-decoration: unset;
    svg {
        min-width: 1.5em;
        max-width: 1.5em;
        @include box(1.5em);
    }
    &:hover {
        text-decoration: underline;
    }
}
