.request-card {
    @include media(lg) {
        display: flex;
        align-items: flex-start;
        gap: min(2.5vmax, 1.25em);
        max-width: 60em;
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: min(2.5vmax, 1.25em);
        width: 100%;
        max-width: min(50vmax, 40em);

        @include media(md) {
            padding: min(2.5vmax, 1.25em);
            box-sizing: border-box;
            box-shadow: 0 0 6px rgba(black, 0.15);
            border-radius: 5px;
            margin-bottom: 1.25rem;
        }

        // &.--content-main-info {}

        // В заявках на театры
        &.--content-contact-person {
            display: block;

            .request-card__title {
                font-size: min(3vmax, 1.5em);
                margin-bottom: 1.25rem;
            }

            @include media(md, max) {
                border-top: 1px solid var(--g300);
                margin-top: min(2.5vmax, 1.25em);
            }
        }

        // В заявках на фестивали
        &.--content-video {
            align-self: stretch;
            width: min(30vmax, 24em);
        }
    }

    &__dates {
        @extend %reset-list;
        padding-bottom: min(2.5vmax, 1.25em);
        border-bottom: 1px solid var(--g300);
    }

    &__date {
        margin: 0;
        line-height: 2;
        color: var(--darkLow);
    }

    &__date-accent {
        font-weight: 600;
        color: var(--dark);
    }

    &__title {
        margin: 0;
        font-size: min(3.5vmax, 2em);
        text-transform: uppercase;
        font-weight: 700;
    }

    &__subtitle {
        margin: 0;
        font-size: min(2.75vmax, 1.5em);
        font-weight: 600;
    }

    &__text {
        margin: 0;
        font-size: min(2.75vmax, 1.5em);
        font-weight: 500;
        word-break: break-word;
    }

    &__description {
        display: block;
        margin: 0;
        line-height: 150%;
        color: var(--darkLow);
        font-size: min(1.6vmax, 1rem);
        font-weight: normal;
        max-width: 45rem;
    }

    &__item {
        display: inline-block;
        font-size: min(2.5vmax, 1.25em);
        font-weight: 500;

        &::before {
            color: var(--primary);
        }
    }

    &__btn {
        @include media(md) {
            align-self: flex-end;
        }
    }

    &__controls {
        display: flex;
        justify-content: flex-end;
        gap: 0.5em;
        flex-wrap: wrap;
    }

    &__list {
        @extend %reset-list;
        grid-column: 2/3;

        display: flex;
        gap: 0.5em;
        flex-direction: column;
    }

    &__link {
        color: var(--dark);
        font-weight: 500;
    }
}
