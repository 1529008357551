%reset-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

%reset-btn {
    all: unset;
    font-size: 1em;
    cursor: pointer;
    box-sizing: border-box;
    // appearance: none;
    // outline: none;
    // border: none;
    // background: none;
}

%reset-input {
    // all: unset;
    appearance: none;
    outline: none;
    // border: unset;
    width: 100%;
    // font-family: inherit;
    // font-size: 1em;
    color: var(--dark);
    // box-sizing: border-box;
}

%reset-checkbox {
    appearance: none;
    outline: none;
    margin: 0;
    font-size: 1rem;
}

%icon-on-before {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialised;
}

%card-design {
    background-color: var(--white);
    box-shadow: 0 0 15px rgba(black, 0.15);
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
        box-shadow: 0 0 15px rgba(black, 0.26);
    }
}

%alt-text-on-after {
    position: relative;
    overflow: hidden;
    &:after {
        content: attr(alt);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: 0.75em;
        box-sizing: border-box;
        color: var(--dark);

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-image: linear-gradient(
            to bottom,
            var(--g200) 0%,
            var(--g100) 50%,
            var(--g200) 100%
        );
    }
}

%hidden-block {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: visibility 0s 0.35s, opacity 0.35s;
}

%visibility-block {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transition: visibility 0s, opacity 0.35s;
}

%cutText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

%main-width {
    width: var(--main-width);
    max-width: 96em;
    margin: 0 auto;
    padding-left: min(2.25vmax, 3em);
    padding-right: min(2.25vmax, 3em);
    box-sizing: border-box;
}

%padding-section {
    padding-top: min(4.375vmax, 4.375em);
    padding-bottom: min(4.375vmax, 4.375em);
}
