@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.map {
    margin-top: calc(min(4.375vmax, 4.375em) * -1);
    margin-left: calc(min(2.25vmax, 3em) * -1);
    margin-right: calc(min(2.25vmax, 3em) * -1);
    margin-bottom: min(4vmax, 2.5em);
    width: calc(100% + min(4.5vmax, 6em));
    aspect-ratio: 1/1;
    border-radius: 5px;
    overflow: hidden;

    @include media(md) {
        aspect-ratio: 1.344/0.526;
    }
}

.search {
    margin-bottom: 2.5em;
}

.list {
    @extend %reset-list;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
    gap: min(2vmax, 1.25em);
    margin-bottom: 2.5em;
}
