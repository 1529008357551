@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.menu {
    margin: 0;
    padding: var(--header-height) 0 0;
    box-sizing: border-box;

    display: flex;

    overflow-y: hidden;

    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 999;
}

.container {
    width: var(--menu-width);

    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    color: var(--white);
    background-image: var(--primary-gradient);
    background-color: var(--primary);
}

.logo {
    display: none;
    background-color: var(--white);
    padding: 0.5em 1.25em;
    box-sizing: border-box;
    object-fit: contain;
}

.list {
    @extend %reset-list;
    flex: auto;
    overflow-y: auto;

    padding: 1.25em 0.5em 0.5em;

    display: flex;
    flex-direction: column;
    gap: 0.75em;

    @include scroll-bar();
}

.link {
    text-decoration: none;
    color: inherit;

    display: flex;
    gap: 0.5em;
    align-items: center;
    padding: 0.5em 0.875em;
    cursor: pointer;
    border-radius: 25px;
    transition: color 0.25s ease-in-out;

    position: relative;
    z-index: 1;

    &_actived {
        cursor: default;
        color: var(--dark);
        background-color: var(--white);

        // &::before,
        // &::after {
        //     content: "";
        //     position: absolute;
        //     display: block;
        //     top: -50px;
        //     right: 0;
        //     width: 20px;
        //     height: 50px;
        //     border-radius: 0 0 25px 0;
        //     box-shadow: 0 25px 0 0 var(--g100);
        //     z-index: -1;
        // }

        // &::after {
        //     top: auto;
        //     bottom: -50px;
        //     border-radius: 0 25px 0 0;
        //     box-shadow: 0 -25px 0 0 var(--g100);
        // }

        & .icon {
            color: var(--dark);
        }
    }

    &:not(.link_actived):hover {
        background-color: rgba($color: white, $alpha: 0.15);
    }
}

.icon {
    @include box(1.875em);
    color: var(--white);
    transition: color 0.25s ease-in-out;
}

.text {
    margin: 0;
    flex: auto;
}

.button {
    flex: none;
    margin: 0.5em;
    width: unset;
    max-width: unset;
    border-radius: 25px;

    display: flex;
    background: rgba($color: white, $alpha: 0.15);

    &:hover {
        background: rgba($color: white, $alpha: 0.26);
    }

    & > span:first-child {
        margin-left: auto;
        font-size: 200%;
    }
}

// подложка по которой кликать, чтобы окно закрыть.
.back {
    display: none;
}

@include media(sm, max) {
    .menu {
        padding: 0;
        @include box(100%);
        z-index: 1001;
        background-color: transparent;
        transition: background-color 0.35s ease-in-out;
        pointer-events: none;
        user-select: none;

        &_opened {
            background-color: rgba($color: black, $alpha: 0.54);
            pointer-events: unset;
            user-select: unset;

            .container {
                transform: translateX(0);
            }
        }

        .logo {
            display: unset;
        }
    }

    .container {
        width: 17em;

        box-shadow: 0 0 15px rgba($color: black, $alpha: 0.15);
        transition: transform 0.35s ease-in-out;
        transform: translateX(-120%);
    }

    .back {
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @include box(100%);
    }

    .button {
        display: none;
    }
}

@include media(sm) {
    .menu {
        &_size_sm {
            .list {
                align-items: center;
            }
            .link {
                padding: 0.5em;
            }

            .text {
                display: none;
            }

            .button span:first-child {
                transform: scale(-1, 1);
            }
        }

        &_size_md {
            .link {
                flex-direction: column;
                text-align: center;
                padding: 0.5em;
                gap: 0.25em;
            }

            .text {
                max-width: 6rem;
                font-size: 0.75em;
            }
        }
    }
}
