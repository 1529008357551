@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.section {
    &_bg_g100 {
        background-color: var(--g100);
    }
}

.wrap {
    @extend %main-width;
    @extend %padding-section;

    &_shadow {
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(black, 0.15);
    }
    &_bubble {
        padding-top: min(3.25vmax, 2.5em);
        padding-bottom: min(3.25vmax, 2.5em);
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(black, 0.15);

        background-image: url("../../../public/images/background-bubble.png");
        background-position: center;
        background-repeat: repeat-y;
        background-size: cover;

        /*
        position: relative;
        z-index: 1;
        overflow: hidden;

        &:first-child {
            margin-top: min(4.375vmax, 4.375em);
        }
        &:last-child {
            margin-bottom: min(4.375vmax, 4.375em);
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: -10vw;
            right: -10vw;
            width: 35vw;
            height: 35vw;
            border-radius: 50%;
            background-color: #eef2f6;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            bottom: -7vw;
            left: -5vw;
            width: 20vw;
            height: 20vw;
            border-radius: 50%;
            background-color: #eef2f6;
        }
        */
    }
}

.title {
    margin: 0;
    margin-bottom: clamp(1.875rem, 1.5229rem + 1.7606vw, 3.125rem);
    font-family: var(--ff-firm);
    font-weight: 700;
    font-size: min(4vmax, 3.75em);
    text-transform: uppercase;
    color: var(--public-primary);

    &_underline::after {
        content: "";
        display: block;
        margin-top: 10px;
        max-width: 5.5em;
        border-bottom: 2px solid var(--public-accent);
    }
}

.titleIcon {
    width: 0.5em;
    margin-left: 0.125em;
    display: inline-block;
    vertical-align: middle;
    & svg {
        @include box(100%);
        fill: var(--public-primary);
    }
}

.subtitle {
    margin: 0;
    margin-bottom: min(5vmax, 2.5rem);
    font-family: var(--ff-firm);
    font-weight: 600;
    font-size: min(3vmax, 2em);
    text-transform: uppercase;
    color: var(--public-accent);
}

.twoColumns {
    display: flex;
    flex-direction: column;
    gap: 1.25em;

    @include media(lg) {
        flex-direction: row;
    }
}

.link {
    margin: 0;
    text-decoration: none;
    font-size: 100%;
    font-weight: 600;
    color: var(--public-primary);

    &:any-link {
        cursor: pointer;
        max-width: max-content;

        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0.125em;
            background-color: var(--public-primary);
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform 0.25s ease-in-out;
        }

        &:hover::before {
            transform-origin: left;
            transform: scaleX(1);
        }
    }
}

.linkButton {
    text-decoration: none;
    color: var(--public-primary);
    font-size: 1.25em;
    line-height: 1.5;

    display: block;
    margin: 1em auto;
    max-width: max-content;
    padding: 0.5em 1.25em;
    border-radius: 5px;
    box-sizing: border-box;
    transition: 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
        color: var(--public-primary);
        background-color: rgba(map-get($colors, "public-primary"), 10%);
    }

    &_primary {
        @extend .linkButton;
        color: var(--white);
        background-color: var(--public-primary);

        &:hover {
            color: var(--white);
            background: darken(map-get($colors, "public-primary"), 5%);
        }

        &:active {
            background: darken(map-get($colors, "public-primary"), 10%);
        }
    }
}

.concord {
    flex: auto;
    display: flex;
    flex-direction: column;

    .linkButton {
        margin-top: 0;
        margin-right: 0;
    }
}

.concordImg {
    display: block;
    width: 100%;
    max-width: min(35vmax, 27.25em);
    object-fit: contain;

    @include media(sm) {
        float: left;
        margin-right: 1.25em;
        margin-bottom: 0.875em;
    }
}

.concordMainText {
    flex: auto;
    font-size: 1.25em;
    line-height: 1.4;

    border-bottom: 1px solid var(--g200);
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    box-sizing: border-box;
}

// Новости на главной
.news {
    flex: 0 0 min(29.5vmax, 29.5em);
    display: flex;
    flex-direction: column;

    @include media(lg) {
        padding: 0 1.25em;
        box-sizing: border-box;
    }

    .linkButton {
        margin-top: 0;
    }
}
.new {
    flex: auto;

    @extend %reset-list;

    border-bottom: 1px solid var(--g200);
    padding-bottom: 1.25em;
    margin-bottom: 1.25em;
    box-sizing: border-box;

    text-decoration: none;
    color: inherit;

    &:hover .newImg {
        outline-color: var(--public-primary);
    }
}
.newImg {
    width: 100%;
    max-width: min(35, 29.5em);
    object-fit: cover;
    aspect-ratio: 16/9;
    margin-bottom: 1.25em;

    outline: 2px solid transparent;
    outline-offset: -10px;
    transition: 0.35s ease-in;
}
.newDate {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
}
.newTitle {
    margin: 0;
    font-size: 1.375em;
    font-weight: 600;
}
.newPharagraph {
    margin: 0;
    font-size: 1.125em;
    color: var(--darkLow);

    @include clamp(3, 1.35);
}

// Друзья содружества
.qoute {
    display: grid;
    align-content: center;
    justify-items: center;
    gap: 0.675em 1.25em;
    padding: min(2vmax, 1.875em);
    box-sizing: border-box;
    box-shadow: 0 0 15px rgba(black, 0.15);
    transition: box-shadow 0.25s ease-in;
    border-radius: 5px;
    &:hover {
        box-shadow: 0 0 15px rgba(black, 0.26);
    }

    @include media(sm) {
        @include box(100%);
    }

    @include media(md) {
        justify-items: start;
        grid-template-columns: auto 1fr;
    }
}
.qouteImg {
    width: 10em;
    display: block;
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;

    @include media(md) {
        grid-row: span 2;
        align-self: center;
    }
}
.qouteTitle {
    margin: 0;
    font-size: 1.25em;
    font-weight: 600;

    @include media(md, max) {
        text-align: center;
    }
}
.qouteTitleSpan {
    display: block;
    font-size: 0.8em;
}
.qouteText {
    margin: 0;
    font-style: italic;
}
.cardDeck {
    @extend %reset-list;
    display: grid;
    gap: min(2vmax, 1em);

    @include media(md) {
        grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));
    }
}

/* События партнеров на главной блок внизу
.card {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;

    text-decoration: none;
    color: inherit;
}
.cardImage {
    position: relative;
    @include box(100%, auto);
    aspect-ratio: 16/9;
    border-radius: 5px;
    overflow: hidden;

    img {
        @extend %alt-text-on-after;
        @include box(100%);
        object-fit: cover;
        display: block;
    }

    &::after {
        content: " ";
        position: absolute;
        display: block;
        @include box(100%);
        top: 0;
        left: 0;
        background-image: linear-gradient(
            90deg,
            black 0%,
            rgba(0, 0, 0, 0) 80%
        );
    }
}
.cardAgeRange {
    position: absolute;
    top: 1rem;
    left: 1.5rem;

    margin: 0;
    font-size: 0.875em;
    color: var(--lightLow);
}
.cardTitle {
    margin: 0;
    line-height: 1.5;
    font-size: min(3.25vmax, 1.875em);
    font-weight: 600;
    padding-left: 1.5rem;
}
.cardList {
    @extend %reset-list;
    display: flex;
    gap: 0.5em;
    padding-left: 1.5rem;
}
.cardItem {
    font-size: 0.875em;
    margin: 0;
    display: flex;
    align-items: center;

    &:not(:first-child)::before {
        content: " ";
        @include box(0.375em);
        background-color: var(--dark);
        border-radius: 50%;
        margin-right: 0.375em;
    }
}
.cardButton {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    left: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 25px;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    min-height: unset;
}
*/

// Карточки спектаклей
.posterCard {
    display: flex;
    background-color: var(--white);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: box-shadow 0.25s ease-in;

    &:hover {
        box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.26);
    }

    &:any-link {
        text-decoration: none;
        color: inherit;
    }

    // Спектакли в этом месяце
    &_topList {
        height: 100%;

        display: flex;
        flex-direction: column;

        .posterCardImgLink {
            position: relative;
            border-radius: 5px;

            &:hover .posterCardImg {
                transform: scale(1.05) translateY(-0.25em);
                filter: brightness(90%);
            }

            @include media(sm) {
                flex: unset;
                max-width: unset;
                min-width: unset;
                aspect-ratio: 2.15/3.06;
                border-radius: 5px;
            }
        }

        .posterCardImg {
            filter: brightness(70%);
        }

        .posterCardTitle {
            text-transform: unset;
        }

        .posterCardButton {
            position: absolute;
            bottom: 1em;
            left: 1em;
        }
    }

    @include media(sm, max) {
        flex-direction: column;
    }
}
.posterCardImgLink {
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    &:any-link {
        text-decoration: none;
        color: inherit;
    }

    &:hover .posterCardImg {
        transform: scale(1.05) translateY(-0.25em);
    }

    @include media(sm) {
        flex: 0 0 30%;
        max-width: 30%;
        min-width: 30%;
        aspect-ratio: 2/2.8;
        border-radius: 5px 0 0 5px;
    }
}
.posterCardImg {
    display: block;
    @extend %alt-text-on-after;
    @include box(100%);
    object-fit: contain;
    transition: all 0.35s;
}
.posterCardMainText {
    flex: auto;

    display: flex;
    flex-direction: column;
    gap: 0.75em;
    padding: 1em;
    box-sizing: border-box;
}
.posterCardTitle {
    margin: 0;
    font-size: min(2.5vmax, 1.25em);
    text-transform: uppercase;
    font-weight: 700;
}
.posterCardTitleSpan {
    display: block;
    font-weight: normal;
    font-size: 75%;
    margin-top: 0.5em;
}
.posterCardList {
    @extend %reset-list;
}
.posterCardItem {
    margin: 0;
    line-height: 1.4;
}
.posterCardAccent {
    font-weight: 700;
    padding-left: 0.25em;
}
.posterCardDescription {
    font-size: min(1.75vmax, 0.875em);
}
.posterCardFooter {
    margin-top: auto;

    display: flex;
    gap: 1em;
    align-items: center;
    flex-wrap: wrap;
}
.posterInfoText {
    margin: 0;
    font-size: min(1.5vmax, 0.75em);
}
.posterCardButton {
    @extend .linkButton_primary;
    font-size: 1em;
    text-transform: uppercase;
    padding: 0.25em 0.5em;
    margin: 0;
}

// Карточка театра маленькая
.theatreCard {
    display: grid;
    grid-template-rows: auto 1fr;
    border: 1px solid var(--g300);
}
.theatreCardImageLink {
    width: 100%;
}
.theatreCardImage {
    display: block;
    @extend %alt-text-on-after;
    width: 100%;
    object-fit: contain;
    transition: transform 0.35s;

    @include media(sm) {
        aspect-ratio: 1/1;
    }
}
.theatreCardMainInfo {
    display: grid;
    grid-template-rows: 1fr 1fr auto;
    gap: 1em;
    padding: min(2.25vmax, 1.125em) min(2.25vmax, 1.125em) 0;
    box-sizing: border-box;
}
.theatreCardTitle {
    margin: 0;
    font-size: min(1.75vmax, 1.25em);
    font-weight: 700;
    box-sizing: border-box;
    text-transform: uppercase;
    word-break: break-word; // Для переноса длинных заголовков без пробелов
}
.theatreCardSubtitle {
    margin: 0;
    font-size: min(1.75vmax, 1.25em);
}
.theatreCardButton {
    @extend .linkButton;
    color: var(--darkLow);
    margin: auto 0 0;
    width: 100%;
    text-align: center;
    max-width: unset;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0;
    border-top: 1px solid var(--g300);

    &:hover {
        color: var(--public-primary);
        background-color: transparent;
    }
}

// Формы и поля
.formWrapper {
    margin: min(5vmax, 2.5em) auto 0;
    width: 100%;
    max-width: 45em;
    padding: 1em min(3vmax, 2em);
    box-sizing: border-box;
    text-align: center;
    background-color: white;
    box-shadow: 0px 0px 24px -2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    @include media(md) {
        padding: min(4vmax, 3em) min(10vmax, 6.75em);
    }
}
.formTitle {
    margin: auto 0 1em;
    font-size: 1.5em;
    font-weight: normal;
    text-transform: uppercase;
}
.formFieldset {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 1em;
    text-align: left;

    @include media(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.formRow {
    grid-column: 1/-1;
}
.fieldTikets {
    grid-column: 1/-1;
    display: flex;
}
.fieldTiketsInput {
    flex: auto;
    & div {
        border-radius: 5px;
    }
}
.fieldTiketsLabel {
    margin: 0;
    composes: label from "../../components/field/field.module.scss";
}
