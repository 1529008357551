@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.notif {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #0000008a;
  overflow-y: auto;
  display: none;

  padding: min(1.5vmax, 1.875em);
  box-sizing: border-box;

  animation: fadeIn 0.5s ease-in-out;

  &_state_info .body {
    color: var(--info);
  }

  &_state_success .body {
    color: var(--success);
  }

  &_state_error .body {
    color: var(--error);
  }

  &_opened {
    display: flex;
  }
}

.container {
  position: relative;
  margin: auto;

  max-width: min(40vmax, 30em);

  @include media(sm, max) {
    max-width: 100%;
  }

  @extend %card-design;
  border-radius: 15px;

  padding: 1.25em 2em;
  box-sizing: border-box;
  animation: notificShow 0.35s ease-out;
  animation-delay: .05s;
}

.body {
  text-align: center;
  color: var(--dark);
}

.caption {
  display: inline-flex;
  gap: .5em;
  margin-bottom: 1.25em;
}

.icon {
  color: inherit;
}

.title {
  color: inherit;
  margin: 0;
  font-size: 1.25em;
  text-transform: uppercase;
  line-height: 1.25;
  font-weight: 600;
}

.text {
  grid-column: 1/-1;
  margin: 0;
  color: var(--darkLow);
}

.controls {
  margin-top: 1.5em;
  display: flex;
  flex-wrap: wrap;
  gap: .675em;
  justify-content: center;

  button {
    min-width: 5em;
    flex: auto;

    &:only-child {
      max-width: 17em;
    }

    &:last-child:nth-child(2) {
      margin-left: auto;
    }
  }
}

@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(notificShow) {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0) scale(0);
  }

  80% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.1);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@include keyframes(notificInfo) {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0) scale(1, 0);
  }

  80% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.2, 1.2);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}