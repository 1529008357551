@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.section {
    background: #dce3f3;
    background: linear-gradient(220deg, #dce3f3, #fbe6ef);
}

.wrap {
    position: relative;
    z-index: 1;
    @extend %main-width;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: var(--white);
}

.img {
    @include box(100%);
    object-fit: contain;
}

.imgLink {
    cursor: pointer;
}
