@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #0000008a;
    overflow-y: auto;
    display: none;

    box-sizing: border-box;

    animation: fadeIn 0.5s ease-in-out;

    @include media(sm) {
        padding: 3em min(1.5vmax, 1.875em);
    }

    &_opened {
        display: flex;
    }
}

.container {
    position: relative;

    width: max-content;
    max-width: 100%;
    margin: auto;

    @extend %card-design;
    // border-radius: 15px;

    animation: PopupShow 0.55s ease-in-out;
    animation-delay: 0.05s;

    @include media(sm) {
        border-radius: 15px;
    }
}

.title {
    margin: min(3vmax, 1.875rem) min(3vmax, 2.5rem) 0;
    text-align: center;
    font-size: min(2.5vmax, 1.875em);
    font-weight: 600;
}

.close {
    @extend %reset-btn;
    position: absolute;
    top: -2.5em;
    right: -2.5em;
    color: var(--light);

    svg {
        fill: currentColor;
        @include box(2.5em);
        transition: 0.25s ease-in;
    }

    &:hover svg {
        color: var(--error);
        transform: rotate(90deg);
    }

    &:active svg {
        transform: translateY(1px) rotate(90deg);
    }

    @include media(sm, max) {
        right: 0.5em;
        top: 0.5em;
        color: var(--darkLow);

        svg {
            @include box(1.875em);
        }
    }
}

.body {
    padding: min(3vmax, 1.875em) min(3vmax, 2.5em) 0;
    margin-bottom: min(3vmax, 1.875em);
    box-sizing: border-box;
}

.controls {
    display: flex;
    gap: 0.675em;
    justify-content: flex-end;
    margin-bottom: min(2.5vmax, 1.25em);

    @include media(sm, max) {
        justify-content: center;
    }

    & button {
        &:only-child {
            margin: auto;
            width: 80%;
        }

        width: min(14vmax, 8em);
    }
}

@include keyframes(fadeIn) {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@include keyframes(PopupShow) {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
