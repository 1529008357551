@import "../styles/_assets/variables";
@import "../styles/_assets/mixins";
@import "../styles/_assets/extends";

.error_page {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    padding: 15vmax 1em;
    box-sizing: border-box;
    z-index: 1;

    @include media(sm) {
        justify-content: center;
        padding: 1em;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../public/images/theatre-boy.png");
        background-repeat: no-repeat;
        background-size: min(38vmax, 28em);
        background-position: calc(100% + min(30vmax, 20em)) bottom;
        opacity: 0;
        animation: boy 0.75s cubic-bezier(0.53, 0.32, 0.59, 1.14) both;
        z-index: -1;
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    background-color: var(--public-primary);
    animation: fade 2s cubic-bezier(0.075, 0.82, 0.59, 1.14) infinite;
    opacity: 0.54;
    transform: scale(0);

    &_big {
        z-index: -1;
        right: calc(min(35vmax, 25em) * -1);
        bottom: calc(min(20vmax, 15em) * -1);
        @include box(min(70vmax, 50em));
        animation-duration: 3s;
    }

    &_big_two {
        z-index: -1;
        right: calc(min(35vmax, 25em) * -1);
        bottom: calc(min(20vmax, 15em) * -1);
        @include box(min(60vmax, 40em));
        animation-duration: 3s;
        animation-delay: 0.15s;
    }

    &_first {
        right: min(6vmax, 4em);
        bottom: min(60vmax, 41em);
        @include box(min(6.5vmax, 3.25em));
    }
    &_second {
        right: min(18vmax, 11em);
        bottom: min(42vmax, 33em);
        @include box(min(6vmax, 4.5em));
        animation-delay: 0.2s;
    }
    &_third {
        right: min(28vmax, 16em);
        bottom: min(32vmax, 21em);
        @include box(min(5vmax, 3em));
        animation-delay: 0.5s;
    }
    &_fourth {
        right: min(22vmax, 18em);
        bottom: min(15vmax, 10em);
        @include box(min(4vmax, 2.5em));
        animation-delay: 0.8s;
    }
    &_five {
        right: min(11vmax, 7em);
        bottom: min(50vmax, 37em);
        @include box(min(2.5vmax, 1.5em));
        animation-delay: 1.2s;
    }
    &_six {
        right: min(16vmax, 13em);
        bottom: min(8vmax, 5em);
        @include box(min(2.5vmax, 1.5em));
        animation-delay: 1.5s;
    }
}
.title {
    font-family: var(--ff-firm);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1.875rem;
    display: flex;
    flex-direction: column;
}

.span_accent {
    color: var(--public-primary);
    font-size: 125%;
}

.text {
    font-size: 1.25em;
}

@include keyframes(fade) {
    100% {
        opacity: 0;
        transform: scale(1.25);
    }
}

@include keyframes(boy) {
    100% {
        opacity: 1;
        background-size: min(40vmax, 30em);
        background-position: calc(100% + min(20vmax, 15em)) bottom;
    }
}
