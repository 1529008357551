@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.pagin {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: min(3vmax, 1.875em);
    margin: min(3vmax, 1.875em) 0;
}

.thumb {
    flex: none;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: min(4vmax, 2.5em);
    max-width: min(4vmax, 2.5em);
    @include box(min(4vmax, 2.5em));
    border: 1px solid var(--g300);
    border-radius: 3px;
    padding: 0;
}

.list {
    @extend %reset-list;
    display: flex;
    gap: 0.375em;

    max-width: 100%;
    overflow-x: auto;
}

.item {
    flex: none;
    min-width: min(4vmax, 2.5em);
    max-width: min(4vmax, 2.5em);
    @include box(min(4vmax, 2.5em));
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--g300);
    border-radius: 3px;
    cursor: pointer;

    &:not(.item_actived):hover {
        color: var(--public-primary);
    }

    &_actived {
        cursor: default;
        border-color: var(--public-primary);
        color: var(--public-primary);
    }
}
