.request-status {
  margin: 0;

  text-align: center;
  padding: .25em 1.25em;
  box-sizing: border-box;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  border: 2px solid transparent;

  &.--status-new {
    background-color: rgba(map-get($colors, "alert"), $alpha: .1);
    border-color: var(--alert);
    color: var(--alert);
  }

  &.--status-accept {
    background-color: rgba(map-get($colors, "success"), $alpha: .1);
    border-color: var(--success);
    color: var(--success);
  }

  &.--status-decline {
    background-color: rgba(map-get($colors, "error"), $alpha: .1);
    border-color: var(--error);
    color: var(--error);
  }

  &.--status-callback {
    background-color: rgba(map-get($colors, "g300"), $alpha: .1);
    border-color: var(--g300);
    color: var(--g300);
  }

  &.--status-review {
    background-color: rgba(map-get($colors, "info"), $alpha: .1);
    border-color: var(--info);
    color: var(--info);
  }

  &.--place-table {
    font-size: .75em;
    font-weight: 500;
    border-width: 1px;
    padding: 0 .75em;
  }
}