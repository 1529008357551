// После переноса компонента file.selector можно будет удалить

.gallery-form {
    @extend %reset-list;

    display: grid;
    gap: 0.675em;
    grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));

    &__item {
        position: relative;
        border-radius: 25px;
        overflow: hidden;
        aspect-ratio: 1/0.6666667;

        &_portrait {
            aspect-ratio: 210/297;
        }

        &:hover .gallery-form__item-panel,
        &:hover .gallery-form__thumbs {
            opacity: 1;
            pointer-events: unset;
            user-select: unset;
        }

        &_is_changed {
            border: 2px solid var(--info);

            &::after {
                content: "NEW";
                position: absolute;
                bottom: 0.5em;
                right: 0.5em;

                display: flex;
                align-items: center;
                justify-content: center;

                @include box(2.75rem);
                font-size: 0.75em;
                color: var(--info);
                font-weight: bold;
                background-color: var(--white);
                border-radius: 50%;
                box-shadow: 0 0 15px 1px rgba(black, 0.15);
            }
        }
    }

    &__item-panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0.675em;
        display: flex;
        justify-content: flex-end;
        gap: 0.375em;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        transition: opacity 0.25s ease-in-out;
    }

    &__thumbs {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        padding: 0.675em;
        box-sizing: border-box;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        transition: opacity 0.25s ease-in-out;
    }

    &__current-position {
        position: absolute;
        bottom: 0.675em;
        left: 0.675em;

        display: flex;
        align-items: center;
        justify-content: center;

        @include box(2em);
        font-weight: 500;
        padding: 0.25em;
        box-sizing: border-box;
        background-color: rgba($color: white, $alpha: 0.75);
        border-radius: 50%;
        color: var(--garkLow);
        cursor: default;
    }

    &__title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color: white, $alpha: 0.75);
        text-align: center;
        color: var(--gark);
        font-weight: 500;
        padding: 0.675em;
        box-sizing: border-box;
    }

    &__img {
        display: block;
        @include box(100%);
        object-fit: cover;
    }

    &__btn {
        background-image: unset;
        background-color: rgba($color: white, $alpha: 0.54);
        color: var(--garkLow);

        &:hover {
            background-color: rgba($color: white, $alpha: 0.84);
            color: var(--gark);
        }
    }

    &__download-block {
        padding: min(2.5vmax, 1.875em);
        box-sizing: border-box;
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: var(--g100);
        border: 1px dashed var(--g300);
        border-radius: 25px;
        transition: background-color 0.25s ease-in-out;
        cursor: default;

        &:hover {
            background-color: var(--g200);
        }
    }

    &__download-text {
        margin: 0;
        text-align: center;
    }

    &__download-span {
        margin-top: 0.5rem;
        display: block;
        font-size: 0.75em;
    }
}
