@import "../styles/_assets/variables";
@import "../styles/_assets/mixins";
@import "../styles/_assets/extends";

.content {
    font-family: var(--ff-public);

    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    flex: auto;
}

.header {
    flex: none;
    background-color: var(--g600);
}

.headerWrap {
    @extend %main-width;
    padding: 0;
    display: flex;
    color: var(--white);

    position: relative;
    z-index: 2;
}

.headerLogo {
    flex: none;
    align-self: stretch;
    background-color: var(--white);
    padding: 0.675em min(3vmax, 1.5em);
    width: min(26vmax, 23.875em);
    box-sizing: border-box;

    img {
        width: 100%;
        object-fit: contain;
    }
}

.menu {
    flex: auto;
    margin: 0;
    padding-left: 0;

    display: flex;

    &PlaceHeader {
        @media screen and (max-width: 1423px) {
            flex: none;
            margin-left: auto;
        }
        .menuList {
            @media screen and (max-width: 1423px) {
                justify-content: center;
                flex-direction: column;

                position: absolute;
                top: 100%;
                right: 0;
                width: 20em;
                background-color: var(--white);
                box-shadow: 0 0 15px rgba(black, 0.15);
                border-radius: 0 0 0 5px;
                overflow: hidden;

                transition: all 0.25s ease-in-out;
                transform: translateY(-0.5em);
                opacity: 0;
                visibility: hidden;
                pointer-events: none;

                &Opened {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                    pointer-events: unset;
                }
            }
            @include media(sm, max) {
                left: 0;
                top: 0;
                width: unset;
                min-height: 100vh;
                text-align: center;
            }
        }
        .menuLink {
            @media screen and (max-width: 1423px) {
                text-align: left;
                justify-content: unset;
                display: flex;
                color: var(--dark);
                border-bottom: 1px solid var(--g200);

                &:hover {
                    color: var(--public-primary);
                }
            }

            @include media(sm, max) {
                justify-content: center;
                text-align: center;
                // align-self: center;
                border-bottom: none;
            }
        }
    }

    &PlaceFooter {
        .menuList {
            flex-direction: column;
            gap: 1em;
        }
        .menuLink {
            justify-content: unset;
            padding: 0;
            text-align: left;
            font-weight: normal;
        }
    }
}

.menuList {
    flex: auto;

    @extend %reset-list;
    display: flex;
    justify-content: space-around;
}

.menuLink {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-align: center;
    text-decoration: unset;
    font-size: 1.125em;
    font-weight: 500;
    padding: min(1.5vmax, 1.25em);
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.25s ease-in;

    &:hover {
        color: var(--public-primary);
    }

    &_actived {
        color: var(--public-primary);
        .dropDownArrow {
            transform: scale(1, -1);
        }
    }

    @include media(sm, max) {
        font-size: 1.25em;
    }
}

.dropDownMenuList {
    @extend %reset-list;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--white);
    box-shadow: 0 0 15px rgba(black, 0.15);
    border-radius: 0 0 0 5px;
    overflow: hidden;

    transition: all 0.25s ease-in-out;
    transform: translateY(-0.5em);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &Opened {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        pointer-events: unset;
    }

    .menuLink {
        font-size: 0.875em;
        color: var(--dark);
        border-bottom: 1px solid var(--g200);

        &:hover {
            color: var(--public-primary);
        }

        &_actived {
            color: var(--public-primary);
        }

        @include media(sm, max) {
            border-bottom: none;
        }
    }
}

.hamburger {
    align-self: center;

    flex: none;
    margin: 0 1.5em 0 auto;

    @extend %reset-btn;
    @include box(2.75em);
    border-radius: 5px;
    padding: 0.25em 0.5em;
    box-sizing: border-box;
    transition: all 0.25s ease;

    display: flex;

    &:hover {
        background-color: var(--g500);
    }

    div {
        margin: auto;

        position: relative;
        width: 100%;
        height: 2px;
        background-color: var(--light);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s ease;
        border-radius: 5px;

        &:before,
        &::after {
            content: "";
            position: absolute;
            top: -10px;
            width: 100%;
            height: inherit;
            border-radius: inherit;
            background-color: inherit;
        }
        &::before {
            top: 10px;
        }
    }

    &Opened {
        &:hover > div {
            transform: rotate(225deg);
        }

        div {
            background-color: var(--public-primary);
            transform: rotate(135deg);

            &::before,
            &::after {
                top: 0;
                transform: rotate(90deg);
            }
        }
    }

    @media screen and (min-width: 1424px) {
        display: none;
    }
}

.dropDownMenu {
    position: relative;

    .dropDownMenuList {
        border-radius: 5px;

        @media screen and (max-width: 1423px) {
            box-shadow: none;
            padding-left: min(1.75vmax, 1.25em);

            &Opened {
                position: relative;
            }
        }

        @include media(sm, max) {
            padding-left: 0;
        }
    }
}

.dropDownArrow {
    @include box(1.125em);
    fill: currentColor;
}

.footer {
    flex: none;
    background-color: var(--g600);
}

.footerWrap {
    @extend %main-width;
}

.footerColumns {
    display: flex;
    padding-top: 2em;
    padding-bottom: 1.25em;
    color: var(--light);
    border-bottom: 1px solid var(--g500);

    @include media(md, max) {
        flex-direction: column;
        gap: 1.25em;
    }
}

.footerColumn {
    flex: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media(md, max) {
        border-top: 1px solid var(--g500);
        padding-top: min(1.75vmax, 1.5em);
    }

    &ContentNavigation {
        @include media(md) {
            // Пока подгон без третьей колонки под лого справа
            flex: 0 1 15em;
            margin-left: auto;
        }
        @include media(md, max) {
            border-top: unset;
            padding-top: 0;
        }
    }
    &ContentContact {
        @include media(md) {
            flex-basis: 50%;
        }
        @include media(md, max) {
            order: 2;
        }
    }
}

.footerColumnHeading {
    color: var(--white);
    font-size: 1.25em;
    margin: 0 0 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
}

.link {
    display: inline-flex;
    align-items: center;
    gap: 0.375em;
    font-size: min(2.5vmax, 1.25em);
    font-weight: 500;
    color: var(--light);
    text-decoration: none;
    margin-bottom: 0.675rem;
    transition: color 0.25s ease-in;

    &:hover {
        color: var(--public-primary);
        svg path {
            fill: var(--public-primary);
        }
    }
}

.linkIcon {
    flex: none;
    svg {
        @include box(1.25em);
        path {
            fill: var(--light);
            transition: fill 0.25s ease-in;
        }
    }
}

.loginLink {
    font-weight: 500;
    color: var(--lightLow);
    text-decoration: none;
    margin-top: auto;
    transition: color 0.25s ease-in;

    &:hover {
        color: var(--white);
    }
}

.footerCopyBlock {
    padding: 1.25em 0;
    display: flex;
    gap: 1.25em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.footerCopy {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    // padding: 1.25em 0;
    font-weight: 500;
    color: var(--lightLow);
}

.footerLogo {
    height: 1.25em;
    object-fit: contain;
}
