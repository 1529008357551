@import "../../styles/_assets/variables";
@import "../../styles/_assets/mixins";
@import "../../styles/_assets/extends";

.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;

    display: flex;
    align-items: center;
    padding: 0 0.75em;
    box-sizing: border-box;
    gap: 0.875em;
    background-color: var(--white);
    box-shadow: 0 0 15px rgba(black, 0.15);
}

.logo {
    display: none;
    margin-right: auto;

    img {
        min-width: 8em;
        width: calc(var(--menu-width) - 1.5em);
        height: var(--header-height);
        padding: 0.25em 0;
        box-sizing: border-box;
        object-fit: contain;
    }
}

.burger {
    margin-right: auto;
}

@include media(sm) {
    .logo {
        display: unset;
    }
    .burger {
        display: none;
    }
}
