@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.title {
    font-size: min(2.5vmax, 1.5em);
    line-height: 1.4;
    margin: min(2.5vmax, 1.25rem) 0;
    text-transform: uppercase;
}
.subtitle {
    font-size: min(2vmax, 1.25em);
    line-height: 1.4;
    font-weight: 600;
    margin: min(2.5vmax, 1.25rem) 0;
}
.date {
    font-size: min(2.5vmax, 1.5em);
    margin: 0;
    color: var(--darkLow);
}
.description {
    margin: min(2.5vmax, 1.5rem) 0;
    font-size: min(2vmax, 1.25em);
    line-height: 1.45;
}
.image {
    display: block;
    margin: min(2.5vmax, 1.5em) 0;
    max-width: 100%;
    max-height: 40em;
}
.sliderImage {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 40em;
    object-fit:contain;
    object-position: center;
}
