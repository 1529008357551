@import "../../../styles/_assets/variables";
@import "../../../styles/_assets/mixins";
@import "../../../styles/_assets/extends";

.section {
    margin-bottom: min(2vmax, 1.875em);
}

.twoColumns {
    display: grid;
    gap: min(3vmax, 1.875em);

    border-bottom: 1px solid var(--g200);
    padding-bottom: min(2vmax, 1.875em);
    margin-bottom: min(2vmax, 1.875em);

    @include media(md) {
        grid-template-columns: minmax(25vmax, 17em) 1fr;
    }
}

// .column {}

.infoColumn {
    display: grid;
    gap: 0 min(3vmax, 1.875em);
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
}

.mainImage {
    @extend %alt-text-on-after;
    display: block;
    @include box(100%);
    border-radius: 5px;
    object-fit: contain;
}

.socialBlock {
    margin: 0.75em 0;
    display: grid;
    gap: 0.5em;
    align-items: center;
    grid-template-columns: auto 1fr;
}
.links {
    @extend %reset-list;
    display: flex;
    flex-wrap: wrap;
    gap: 0.375em;
}
.link {
    margin: 0;
    line-height: 1.5;
    text-decoration: none;
    font-size: 1.125em;
    font-weight: 600;
    color: var(--public-primary);

    &:any-link {
        cursor: pointer;
        max-width: max-content;

        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0.125em;
            background-color: var(--public-primary);
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform 0.25s ease-in-out;
        }

        &:hover::before {
            transform-origin: left;
            transform: scaleX(1);
        }
    }

    &:last-child {
        grid-column: 1/-1;
    }
}
.socialLink {
    text-decoration: unset;
    display: flex;
    transition: all 0.15s ease-in;
    background-color: var(--g200);
    border-radius: 50%;
    @include box(1.5em);

    &:hover {
        transform: translateZ(0) scale(1.1);
    }
    & svg {
        margin: auto;
        @include box(80%);
    }
}
.title {
    font-size: min(3vmax, 1.875em);
    margin: 0;
    text-transform: uppercase;
}
.subtitle {
    font-size: min(2.25vmax, 1.25em);
    margin: 0.5em 0 0;
}
.list {
    @extend %reset-list;
    margin: 1em 0;
}
.item {
    line-height: 1.5;
    font-size: min(2.25vmax, 1.25em);
    color: var(--darkLow);

    .link {
        font-size: 100%;
    }
}
.accent {
    color: var(--dark);
    padding-left: 0.5em;
}
.description {
    font-size: min(2vmax, 1.125em);
    line-height: 1.25;

    img {
        max-width: 100% !important;
    }
}

.peopleList {
    @extend %reset-list;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
}
.card {
    display: grid;
    gap: 0 0.675em;
    grid-template-columns: auto 1fr;
    grid-template-rows: min(6vmax, 3.125em) auto;
}
.cardPhoto {
    grid-row: 1/3;
    @include box(min(6vmax, 3.125em));
    object-fit: cover;
    object-position: top center;
    aspect-ratio: 1/1;
    border-radius: 50%;
}
.cardTitle {
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: center;

    margin: 0;
    font-size: 1em;
    font-weight: 700;
}
.cardTitleAccent {
    display: block;
    font-weight: 600;
}
.cardSubtitle {
    margin: 0;
    font-size: 0.875em;
    color: var(--darkLow);
}
.cardList {
    grid-column: 1/-1;
    @extend %reset-list;
}
.cardItem {
    display: flex;
    gap: 1em;
    line-height: 1.5;
    font-size: 1.125em;
    color: var(--darkLow);
}
.cardItemAccent {
    color: var(--dark);
}
.cardDescription {
    grid-column: 1/-1;
    @include clamp(4);
}

.tabs ul li {
    flex: auto;
}

.postersList {
    @extend %reset-list;

    display: grid;
    gap: min(2vmax, 1.25em);

    /*
    li:not(:last-child) {
        border-bottom: 1px solid var(--g200);
        padding-bottom: 1em;
        margin-bottom: 1em;
    }
    */

    @include media(sm) {
        grid-template-columns: repeat(auto-fit, minmax(33em, 1fr));

        .poster:only-child {
            max-width: min(70vmax, 43em);
        }
    }
}

// Спектакль
.poster {
    display: grid;
    gap: 1em;

    background-color: var(--white);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: box-shadow 0.25s ease-in;

    &:hover {
        box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.26);
    }

    &:any-link {
        text-decoration: none;
        color: inherit;
    }
    @include media(md) {
        grid-template-columns: 0.5fr 1fr;
    }
}
.posterImage {
    @include box(100%, auto);
    border-radius: 5px 0 0 5px;

    @extend %alt-text-on-after;
    display: block;
    object-position: top center;
    object-fit: contain;
}
.posterMainText {
    padding: min(2vmax, 1em);

    display: flex;
    flex-direction: column;
}
.posterTitle {
    margin: 0;
    font-size: min(2.875vmax, 1.5em);
    font-weight: 600;
}
.posterDate {
    margin: 1em 0;
    text-transform: uppercase;
    font-size: min(1.75vmax, 1.25em);
    font-weight: 600;
}
.posterDescription {
    margin: 1em 0;
    font-size: min(1.75vmax, 1.125em);
}
.posterInfo {
    margin: auto 0 0.375em;
    font-size: 0.875em;
    text-align: center;
    width: 100%;
    @include media(md) {
        max-width: 15rem;
    }
}
.posterButton {
    width: 100%;
    @include media(md) {
        max-width: 15rem;
    }
}

.galleryList {
    @extend %reset-list;
    display: grid;
    gap: min(3vmax, 1.875em);

    @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(23em, 1fr));
    }
}
.galleryItem div {
    border-radius: 5px;
    aspect-ratio: 4/2.7;
}
.videoList {
    @extend %reset-list;
    display: grid;
    gap: min(3vmax, 1.875em);

    @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
    }
}
.video {
    border-radius: 5px;
    overflow: hidden;
}
